import { useRef, useState } from "react";
// import ContactUsButton from "../../components/contact-us-button";
import Slide from "../../components/slide";
import { MAX_WIDTH } from "../../data/constants";

const OurWorkSection = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slider = useRef(null);
  return <div className="our-work-section">
    <div className="our-work-wrapper">
      <div className="title">{props.title}</div>
      <div className="description-wrapper">
        <div className="description">
          {props.description.map(text => {
            return <div key={text} className="text">{text}</div>
          })}
        </div>
        <div className="slide-switcher">
          <img
            src={props.slideActionButton.left}
            alt="left-nav"
            onClick={() => {
              const toSlide = Math.max(0, currentSlide - 1);
              const offsetWidth = (Math.max(slider.current.offsetWidth - MAX_WIDTH, 0) / 2);
              const left = slider.current.querySelectorAll('.slide-item')[toSlide].offsetLeft - offsetWidth;
              setCurrentSlide(toSlide);
              slider.current.scroll({
                left,
                behavior: 'smooth'
              });
            }}
          />
          <div className="slide-count">{`${currentSlide + 1}`.padStart(2, 0)}/{`${props.slides.length}`.padStart(2, 0)}</div>
          <img
            src={props.slideActionButton.right}
            alt="right-nav"
            onClick={() => {
              const toSlide = Math.min(props.slides.length - 1, currentSlide + 1);
              const offsetWidth = (Math.max(slider.current.offsetWidth - MAX_WIDTH, 0) / 2);
              const left = slider.current.querySelectorAll('.slide-item')[toSlide].offsetLeft - offsetWidth;
              setCurrentSlide(toSlide);
              slider.current.scroll({
                left,
                behavior: 'smooth'
              });
            }}
          />
        </div>
      </div>
      {/* <ContactUsButton {...props.detailButton} linkClassName="our-work-contact" /> */}
    </div>
    <div className="slides-container" ref={slider} onScroll={(event) => {
      const slided = (event.target.scrollLeft + (Math.max(slider.current.offsetWidth - MAX_WIDTH, 0) / 2));
      const slideWidth = ((event.target.scrollWidth - Math.max(slider.current.offsetWidth - MAX_WIDTH, 0)) / props.slides.length);
      const currentSlide = Math.floor(slided/slideWidth);
      setCurrentSlide(currentSlide);
    }}>
      {props.slides.map(slide => {
        return <Slide key={slide.image} {...slide} />
      })}
    </div>
  </div>
};

export default OurWorkSection; 