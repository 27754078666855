import { Link } from 'react-router-dom';
import './open-positions-section.css';

const PositionItem = (props) => {
  return <div className="position-item">
    <div className="position-tag">{props.tag}</div>
    <div className="position-title">{props.positionTitle}</div>
    <div className="position-botton-wrapper">
      <div className="position-description">{props.positionDescription.map(text => <div className="text">{text}</div>)}</div>
      <Link className="position-detail-link" to={`${props.link}/overview`}>
        <img src={props.linkImage} className="position-detail-image" alt="link" />
      </Link>
    </div>
  </div>
};

const OpenPositions = (props) => {
  return <div className="open-positions-section">
    <div className="open-positions-wrapper">
      <div className="title">{props.title}</div>
      <div className="subtitle">{props.subTitle}</div>
      <div className="open-positions-list-title">{props.openPositionsListTitle}</div>
      <div className="open-position-wrapper">
        {props.list.map(position => <PositionItem {...position} />)}
      </div>
    </div>
  </div>
}

export default OpenPositions;
