import { Link } from 'react-router-dom';
import './link-section.css';

const LinkSection = (props) => {
  return <div className="link-section">
    <div className="link-wrapper">
      <Link to={`${props.link}/overview`} className={props.active === 'overview' ? 'active' : ''}>Overview</Link>
      <Link to={`${props.link}/application`} className={props.active === 'application' ? 'active' : ''}>Application</Link>
    </div>
  </div>
};

export default LinkSection;