import './top-section.css';

const TopSection = (props) => {
  return <div className="top-section" style={{ background: props.details.background }}>
    <div className="overlay"></div>
    <div className="top-section-wrapper">
      <div className="page-title">{props.tag}</div>
      <div className="page-subtitle">{props.positionTitle}</div>
      <div className="title-decoration"></div>
      <div className="main-heading-wrapper">{props.details.description}</div>
    </div>
  </div>
};

export default TopSection;