export const sectionElements = {
  heading: (value, id) => {
    return <div id={id} key={value} className="section-heading">{value}</div>
  },
  paragraph: (value) => {
    return <div key={value} className="section-paragraph">{value}</div>
  },
  space: (value) => {
    return <div className="space" key={value} style={{ height: value }}></div>
  },
  image: (value) => {
    return <img key={value} className="article-image" src={value} alt="article" />
  },
  'result-details': (itemList) => {
    return <div className="result-details">
      {itemList.map(item => {
        return <div key={item.title} className="result-item">
          {sectionElements.heading(item.title)}
          {sectionElements.paragraph(item.description)}
        </div>
      })}
    </div>
  },
  'bullet-points': (list) => {
    return list.length ? <ul className="bullet-points">
      {list.map(text => {
        return <li className="bullet-point-item" key={text}>{text}</li>
      })}
    </ul> : null;
  }
};