import { Link } from 'react-router-dom';
import './styles.css';

const ContactUsButton = (props) => {
  return props.isExternal ?
    <a className={`contact-us ${props.linkClassName}`} href={props.link}>
      <div className="button-text">{props.title}</div>
      <img loading="lazy" src={props.iconSvg} alt="link" />
    </a> :
    <Link className={`contact-us ${props.linkClassName}`} to={props.link}>
      <div className="button-text">{props.title}</div>
      <img loading="lazy" src={props.iconSvg} alt="link" />
    </Link>
};

export default ContactUsButton;