import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from '../../components/footer';
import Header from '../../components/header';

import aboutUsData from '../../data/about.json';
import carrersData from '../../data/careers.json';
import footerData from '../../data/footer.json';
import headerData from '../../data/header.json';
import homeData from '../../data/home.json';
import serviceData from '../../data/services.json';
// import workDetailsData from '../../data/work-details.json';
import workData from '../../data/work.json';
// import blogData from '../../data/blog.json';
// import contactUsData from '../../data/contact-us.json';
import contactUsDataV2 from '../../data/contact-us-v2.json';

import AboutUs from '../about';
// import Blog from '../blog';
import Careers from '../careers';
import CareersDetails from '../career-details'
// import ContactUs from '../contact-us';
import ContactUsV2 from '../contact-us-v2';
import Error from '../error';
import Home from '../home';
import Services from '../services';
import Work from '../work';
// import WorkDetails from '../work-details';
// import BlogDetails from '../blog-details';

const routes = [
  {
    path: '/',
    element: <Home {...homeData} />
  },
  {
    path: '/services',
    element: <Services {...serviceData} />
  },
  /* ...Object.keys(workDetailsData).map((path) => {
    return {
      path: `/our-work/${path}`,
      element: <WorkDetails {...workDetailsData[path]} />
    }
  }), */
  {
    path: '/our-work',
    element: <Work {...workData} />
  },
  {
    path: '/about-us',
    element: <AboutUs {...aboutUsData} />
  },
  {
    path: '/careers',
    element: <Careers {...carrersData} />
  },
  ...(carrersData.openPositionsSection.list.map(item => {
    return {
      path: item.link,
      element: <CareersDetails {...item} />
    }
  })),
  ...(carrersData.openPositionsSection.list.map(item => {
    return {
      path: `${item.link}/overview`,
      element: <CareersDetails {...item} />
    }
  })),
  ...(carrersData.openPositionsSection.list.map(item => {
    return {
      path: `${item.link}/application`,
      element: <CareersDetails {...item} />
    }
  })),
  /* {
    path: '/blogs/',
    element: <Blog {...blogData} />
  },
  {
    path: '/blogs/:category',
    element: <Blog {...blogData} />
  },
  {
    path: '/blogs/:category/:blogId',
    element: <BlogDetails {...blogData} />
  }, */
  {
    path: '/contact-us',
    element: <ContactUsV2 {...contactUsDataV2} />
  },
  /* {
    path: '/contact-us',
    element: <ContactUs {...contactUsData} />
  }, */
  {
    path: '/404',
    element: <Error />
  },
  {
    path: '/*',
    element: <Error />
  }
];

const App = () => {
  return <BrowserRouter>
    <Header {...headerData} />
    <Routes>
      {routes.map(route => {
        return <Route key={route.path} exact={true} path={route.path} element={route.element}></Route>
      })}
    </Routes>
    <Footer {...footerData} />
  </BrowserRouter>
}

export default App;
