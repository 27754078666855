import { Link, useLocation } from 'react-router-dom';
import { isPrefooterHidden } from '../../utils/prefooter-route';
import ContactUsButton from '../contact-us-button';
import SeamlessLogo from '../seamless-logo';
import './styles.css';

const PreFooter = (props) => {
  return <div className="pre-footer">
    <div className="main-heading-wrapper">
      {props.title.map(title => {
        return <div key={title} className="main-heading">{title}</div>
      })}
    </div>
    <div className="sub-heading-wrapper">
      {props.subtitle.map(subtitle => {
        return <div key={subtitle} className="sub-heading">{subtitle}</div>
      })}
    </div>
    <ContactUsButton {...props.contactButton} />
  </div>
}

const FooterLinks = (props) => {
  return <div className="footer-links">
    <SeamlessLogo logoSrc={props.logoSrc} logoAltText={props.logoAltText} logoTitle={props.logoTitle} link={props.logoLink} />
    <div className="footer-link-list">
      {props.footerNavigationItems.map(item => {
        return <div key={item.id} className="footer-link">
          {item.isExternal ? <a {...item} href={item.to}>{item.title}</a> : <Link {...item}>{item.title}</Link>}
        </div>
      })}
    </div>
    <div className="footer-details">
      {/* <div className="office-address">
        <div className="footer-details-title">office</div>
        {props.officeAddress.map(line => {
          return <div className="detail-line" key={line}>{line}</div>
        })}
      </div> */}
      <div className="contact-details">
        <div className="footer-details-title">contact us</div>
        <div className="detail-line" key={props.contactEmail}>{props.contactEmail}</div>
        <div className="detail-line" key={props.contactNumber}>{props.contactNumber}</div>
      </div>
      <div className="social-media-links">
        {props.socialMediaLinks.map(item => {
          return <a key={item.link} href={item.link} target="_blank" rel="noreferrer">
            <img loading="lazy" src={item.icon} alt={item.link} />
          </a>
        })}
      </div>
    </div>
  </div>
};

const Copyright = (props) => {
  return <div className="copyright">
    <div className="text">&copy; {(new Date()).getFullYear()} {props.text}</div>
    {/* <div className="privacy-policy">
      <Link to={props.privacyPolicy.link}>{props.privacyPolicy.text}</Link>
    </div> */}
  </div>
}

const Footer = (props) => {
  const location = useLocation();
  const isPreFooterHidden = isPrefooterHidden(location.pathname);
  return <footer className="app-footer">
    {isPreFooterHidden ? null : <PreFooter {...props.preFooter} />}
    <FooterLinks {...props.footerLinks} />
    <Copyright {...props.copyright} />
  </footer>
};

export default Footer;