import './service-section.css';

const ServiceSection = (props) => {
  return <div className="service-widget" style={{ background: props.background}}>
    <div className={`service-wrapper ${props.reverse ? 'reverse' : ''}`}>
      <div className="service-image">
        <img {...props.image} alt="services" loading="lazy" />
      </div>
      <div className="service-overview">
        <div className="title">{props.details.title}</div>
        <div className="subtitle">
          {props.details.subtitle.map(text => <div key={text} className="text">{text}</div>)}
        </div>
        <div className="bullet-points">
          {props.details.bulletPoints.list.map(item => {
            return <div key={item.title} className="bullet-point">
              <img src={item.icon} alt="bullet-point-icon" loading="lazy" />
              <div>
                <div className="bullet-text">{item.title}</div>
                {item.description.map(text => <div key={text} className="bullet-description">{text}</div>)}
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  </div>
};

export default ServiceSection;