import { useState } from "react";
import CheckBox from "../../components/check-box";
import Input from "../../components/input";
import SubmitButton from "../../components/submit-button";
import TextArea from "../../components/text-area";
import Uploader from "../../components/upload";
import { cloneObject } from "../../utils/deep-copy";
import './application-section.css';

export const formComponents = {
  input: (props) => <Input {...props} />,
  textArea: (props) => <TextArea {...props} />,
  button: (props) => <SubmitButton {...props} />,
  checkbox: (props) => <CheckBox {...props} />,
  upload: (props) => <Uploader {...props} />
};

export const FormHeader = (props) => {
  return <div className="form-header">
    <div className="form-title">{props.formTitle}</div>
    <div className="clear-wrapper" onClick={props.onClick}>
      <img className="clear-form" src={props.resetFormIcon} alt="delete" />
      <div className="clear-text">{props.resetFormText}</div>
    </div>
  </div>
};

export const Form = (props) => {
  const [formState, setFormState] = useState({});
  const handleClearForm = () => {
    setFormState({});
  };
  return <div className="application-form">
    {props.formTitle ? <FormHeader formTitle={props.formTitle} resetFormIcon={props.resetFormIcon} onClick={handleClearForm} resetFormText={props.resetFormText} /> : null}
    <div className="form-elements">
      {props.components.map(item => {
        const { id, width, component, ...rest } = item;
        const componentProps = {
          style: { width },
          value: formState[id] ?? '',
          onChange: ({ target }) => {
            const newFormState = cloneObject(formState);
            newFormState[id] = target.value;
            setFormState(newFormState);
          },
          ...rest
        };
        return formComponents[component](componentProps);
      })}
    </div>
  </div>
}


const ApplicationSection = (props) => {
  return <div className="application-section">
    {props.formList.map(form => {
      return <Form {...form} />
    })}
  </div>
};

export default ApplicationSection;