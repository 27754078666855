import './styles.css';

const TextArea = (props) => {
  const { style, label, ...rest } = props;
  return <div className="custom-text-area" style={style}>
    <div className="label">{label}</div>
    {props.isRequired ? <sup>*</sup> : null}
    <textarea {...rest} />
  </div>
};

export default TextArea;