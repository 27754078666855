const TrustedByTeamsSection = (props) => {
  return <div className="trusted-section">
    <div className="trusted-section-wrapper">
      <div className="title">{props.title}</div>
      <div className="logo-list">
        {props.imageList.map(imageUrl => {
          return <img key={imageUrl} src={imageUrl} alt="logo" loading="lazy" />
        })}
      </div>
    </div>
  </div>
};

export default TrustedByTeamsSection;