import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ContactUsButton from '../../components/contact-us-button';
import { sectionElements } from '../work-details/section-elements';
import './overview-section.css';

const OverviewSection = (props) => {

  const [activeIndex, setActiveIndex] = useState(0);

  const getOffsetPosition = (id) => {
    const element = document.querySelector(id);
    const headerOffset = 100;
    const elementPosition = element?.getBoundingClientRect().top;
    return elementPosition + window.pageYOffset - headerOffset;
  }
  const handleClickSectionLink = (id) => {
    window.scrollTo({
      top: getOffsetPosition(id),
      behavior: "smooth"
    });
  }

  useEffect(() => {

    const scrollEventListener = () => {
      const elementList = props.leftContent.map(item => getOffsetPosition(item.link));
      const index = elementList.findIndex((top) => top > window.scrollY);
      index !== -1 ? setActiveIndex(Math.max(index - 1, 0)) : setActiveIndex(props.leftContent.length - 1);
    };

    window.addEventListener('scroll', scrollEventListener);
    return () => {
      window.removeEventListener('scroll', scrollEventListener);
    }
  }, [props.leftContent]);

  return <div className="overview-section">
    <div className="overview-wrapper">
      <div className="left-section">
        <div className="left-section-wrapper">
          {props.leftContent.map((item, index) => {
            return <Link key={item.link} to={item.link} className={index === activeIndex ? 'active' : ''} onClick={() => handleClickSectionLink(item.link)}>{item.text}</Link>
          })}
        </div>
      </div>
      <div className="right-section">
        <div className="right-section-wrapper">
          {props.rightContent.map(item => {
            return sectionElements[item.type](item.value, item.id)
          })}
          <ContactUsButton 
            title="Apply Now" 
            iconSvg="/icon_button-icon_white.webp" 
            // link={`${props.link}/application`} 
            // isExternal={true} 
            link="/contact-us"
            linkClassName="career-contact"
          />
        </div>
      </div>
    </div>
  </div>
};

export default OverviewSection;