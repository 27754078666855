// export const cloneObject = (object, retObject) => {
//   return Object.keys(object).reduce((acc, key) => {
//     const value = object[key];
//     if (Array.isArray(value)) {
//       acc[key] = value.map(item => {
//         return cloneObject(item, {});
//       });
//     }
//     else if (typeof value === 'object') {
//       acc[key] = cloneObject(value, {});
//     }
//     else {
//       acc[key] = value;
//     }

//     return acc;
//   }, retObject);
// }

export const cloneObject = (object) => {
  if(object === null || object === undefined) {
    return object;
  } else if (Array.isArray(object)) {
    return object.map(item => cloneObject(item));
  } else if (typeof object === 'object') {
    return Object.keys(object).reduce((acc, key) => {
      acc[key] = cloneObject(object[key]);
      return acc;
    }, {});
  } else {
    return object;
  }
};