import { Link } from 'react-router-dom';
import ContactUsButton from '../../components/contact-us-button';
import './top-section.css';

const TopSection = (props) => {
  return <div className="top-section" style={{ background: props.background }}>
    <div className="top-section-overlay" style={{ ...props.topSectionOverlayStyles }}></div>
    <div className="top-section-wrapper">
      <div className="page-logo">
        <div className="title-decoration"></div>
        <div className="page-title">{props.pageTitle}</div>
      </div>
      <div className="main-heading-wrapper">
        {props.title.map(title => {
          return <div key={title} className="main-heading">{title}</div>
        })}
      </div>
      <div className="link-section">
        <ContactUsButton {...props.contactButton} linkClassName="our-work-detail-contact" />
        <div className="middle-text">{props.middleText}</div>
        <Link className="explore-link" to={props.exploreButton.link}>{props.exploreButton.title}</Link>
      </div>
    </div>
  </div>
};

export default TopSection;