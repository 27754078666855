import OpenPositions from './open-positions-section';
import TopSection from './top-section';

import './styles.css';

const Careers = (props) => {
  return <div className="careers-page">
    <TopSection {...props.topSection} />
    <OpenPositions {...props.openPositionsSection} />
  </div>
}

export default Careers;
