import AboutUsSection from '../home/about-us-section';
import TopSection from './top-section';
import PartnerSection from './partner-section';
import OurCultureSection from './our-culture-section';

const ContactUs = (props) => {
  return <div className="about-us-page">
    <TopSection {...props.topSection} />
    <AboutUsSection {...props.aboutUsSection} />
    <PartnerSection {...props.partnerSection} />
    <OurCultureSection {...props.cultureSection} />
  </div>
}

export default ContactUs;
