import { useState } from "react";
import './styles.css'

const Uploader = (props) => {
  const [file, setFile] = useState();
  const [isBoxActive, setIsBoxActive] = useState(false);

  const onChangeFile = (event) => {
    setFile(event.target.files[0]);
  };

  const activateBox = () => {
    setIsBoxActive(true);
  };

  const deactivateBox = () => {
    setIsBoxActive(false);
  };

  const clearUpload = () => {
    const input = document.getElementById('custom-uploader-input');
    input.value = null;
    setFile();
  };

  return <div className="custom-uploader" style={{ width: props.width || '100%' }}>
    <div className="label-wrapper">
      <div className="label">{props.label}</div>
      {props.isRequired ? <sup>*</sup> : null}
    </div>
    <div className={`input-wrapper ${isBoxActive ? 'active' : ''}`} onDragEnter={activateBox} onDragLeave={deactivateBox} onBlur={deactivateBox} onDrop={deactivateBox}>
      {file ? <div className="preview">
        <div className="left-preview">
          <img src="/icon_careers_attachment.webp" alt="attatchment-icon" />
          <div className="text">{file.name}</div>
        </div>
        <img src="/icon_careers_delete.webp" alt="delete-icon" onClick={clearUpload} />
      </div> : null}
      <div className="upload-text">{file ? <u>Replace file</u> : <u>Upload a file</u>} or drag and drop here</div>
      <input type="file" onChange={onChangeFile} id="custom-uploader-input" />
    </div>
  </div>
};

export default Uploader;