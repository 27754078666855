
export const isPrefooterHidden = (pathname) => {
  if (pathname.indexOf('/careers') !== -1) {
    return true;
  }
  if (pathname.indexOf('/contact-us') !== -1) {
    return true;
  }
  if (pathname.indexOf('/blogs') !== -1) {
    return true;
  }

  return false;
}