// import ContactUsButton from "../contact-us-button";

import './styles.css';

const Slide = (props) => {
  return <div className="slide-item" style={{ backgroundImage: `url('${props.image}')` }} >
    <div className="overlay"></div>
    <div className="slide-content">
      <img className="icon" src={props.icon} alt={props.icon} />
      <div className="slide-text">
        <div className="slide-title">
          {props.title.map(text => {
            return <div className="slide-title-text" key={text}>{text}</div>
          })}
        </div>
        <div className="slide-description">
          {props.description.map(text => {
            return <div className="slide-description-text" key={text}>{text}</div>
          })}
        </div>
      </div>
      {/* <ContactUsButton link={props.link} linkClassName="slide-link" title="Learn More" iconSvg="/icon_button-icon_white.webp" /> */}
      <div></div>
    </div>
  </div>
};

export default Slide;