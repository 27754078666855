import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scrollToTop } from '../../utils/scroll-to-top';
import SeamlessLogo from '../seamless-logo';
import './styles.css';

const CloseIcon = () => {
  return <svg viewBox="0 0 50 50">
    <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
  </svg>
};

const HamburgerIcon = () => {
  return <svg viewBox="0 0 50 50">
    <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" />
  </svg>
}

const Header = (props) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    scrollToTop(location);
  }, [location]);

  return <header className="app-header">
    <div className="app-header-container">
      <div className="left-section">
        <SeamlessLogo logoSrc={props.logoSrc} logoAltText={props.logoAltText} logoTitle={props.logoTitle} link={props.logoLink} />
        <div className="hamburger-icon" onClick={() => setIsMenuVisible(!isMenuVisible)}>
          {isMenuVisible ? <CloseIcon /> : <HamburgerIcon />}
        </div>
      </div>
      <div className={`right-section ${isMenuVisible ? 'visible' : ''}`}>
        {props.headerNavigationItems.map(item => {
          return item.isExternal ?
            <a key={item.id} href={item.to} className="header-link">{item.title}</a> :
            <Link {...item} key={item.id} className={`header-link ${location.pathname === item.to ? 'active' : ''}`} onClick={() => setIsMenuVisible(false)} >{item.title}</Link>
        })}
      </div>
    </div>
  </header>
};

export default Header;