import ContactUsButton from "../../components/contact-us-button";
import "./about-us-section.css";

const AboutUsSection = (props) => {
  return <div className="about-us-section">
    <div className="about-us-wrapper">
      <div className="title">{props.title}</div>
      <div className="about-text">
        <div className="about-description">
          {props.description.map(text => {
            return <div key={text} className="text">{text}</div>
          })}
        </div>
        <ContactUsButton {...props.detailButton} linkClassName="about-us-contact" />
      </div>
    </div>
  </div>
};

export default AboutUsSection;