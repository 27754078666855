import { Link } from 'react-router-dom';
import './work-list-section.css';

const WorkItem = (props) => {
  return <Link to={props.workLink} className={`work-list-item ${props.className}`}>
    <img src={props.imageSrc} alt="work" />
    <div className="link-text-container">
      <div className="link-title">{props.linkTitle}</div>
      <div className="link-description">{props.description}</div>
    </div>
  </Link>
};

export default WorkItem;