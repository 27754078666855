
import './office-section.css';

const Office = (props) => {
  return <div className="office-item">
    <div className="office-title-wrapper">
      <div className="office-decoration"></div>
      <div className="office-name">{props.title}</div>
    </div>
    <div className="office-subtitle">{props.subtitle}</div>
    <div className="office-address">
      {props.address.map(text => {
        return <div className="text">{text}</div>
      })}
    </div>
  </div>
}

const OfficeSection = (props) => {
  return <div className="office-section" style={{ backgroundImage: props.mapImage }}>
    <div className="office-section-wrapper">
      <div className="office-title">{props.title}</div>
      <div className="office-description">
        {props.description.map(text => {
          return <div className="text">{text}</div>
        })}
      </div>
      <div className="office-address-wrapper">
        {props.officeList.map(office => {
          return <Office {...office} />
        })}
      </div>
    </div>
  </div>
};

export default OfficeSection;