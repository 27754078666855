import './our-culture-section.css';

const OurCultureSection = (props) => {
  return <div className="culture-widget">
    <div className="culture-wrapper">
      <div className="culture-image">
        <img {...props.image} alt="cultures" loading="lazy" />
      </div>
      <div className="culture-overview">
        <div className="title">{props.details.title}</div>
        <div className="subtitle">
          {props.details.subtitle.map(text => <div key={text} className="text">{text}</div>)}
        </div>
        <div className="description">
          {props.details.description.map(text => <div key={text} className="text">{text}</div>)}
        </div>
        <div className="bullet-points">
          <div className="bullet-title">{props.details.bulletPoints.title}</div>
          {props.details.bulletPoints.list.map(item => {
            return <div key={item.title} className="bullet-point">
              <img src={item.icon} alt="bullet-point-icon" loading="lazy" />
              <div className="bullet-text">{item.title}</div>
            </div>
          })}
        </div>
      </div>
    </div>
  </div>
};

export default OurCultureSection;