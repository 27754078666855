import { Helmet } from 'react-helmet';
import AboutUsSection from './about-us-section';
import CareerSection from './career-section';
import OurWorkSection from './our-work-section';
import ServiceSection from './service-section';
import TopSection from './top-section';
import TrustedByTeamsSection from './trusted-team-section';

import './styles.css';

const MetaTags = (props) => {
  return <Helmet>
    <title>{props.title}</title>
  </Helmet>
};

const Home = (props) => {
  return <div className="home-page">
    <MetaTags {...props.metaData} />
    <TopSection {...props.topSection} />
    <TrustedByTeamsSection {...props.trustedByTeamSection} />
    <AboutUsSection {...props.aboutUsSection} />
    <OurWorkSection {...props.ourWorkSection} />
    <ServiceSection {...props.serviceSection} />
    <CareerSection {...props.careerSection} />
  </div>
}

export default Home;
