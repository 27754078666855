import { useLocation } from "react-router-dom";
import LinkSection from "./link-section";
import TopSection from "./top-section";
import OverviewSection from "./overview-section";
import ApplicationSection from "./application-section";

const CareerDetails = (props) => {
  const location = useLocation();
  const active = location.pathname.split('/')[3] ?? 'overview';
  return <div className="career-details">
    <TopSection {...props} />
    <LinkSection {...props} active={active} />
    {active === 'overview' ?
      <OverviewSection {...props.details} link={props.link} /> :
      <ApplicationSection {...props.applicationForm} />
    }
  </div>
};

export default CareerDetails;