import ServiceSection from './service-section';
import TopSection from './top-section';

const Services = (props) => {
  return <div className="service-page">
    <TopSection {...props.topSection} />
    {props.serviceSection.map(item => {
      return <ServiceSection {...item} />
    })}
  </div>
};

export default Services;
