import './styles.css';
import TopSection from './top-section';
import WorkItem from './work-list-section';

const Work = (props) => {
  return <div className="our-work-page">
    <TopSection {...props.topSection} />
    <div className="work-list-section">
      {props.workList.map(workItem => {
        return <WorkItem {...workItem} />
      })}
    </div>
  </div>
}

export default Work;
