import './top-section.css';

const TopSection = (props) => {
  return <div className="top-section" style={{ background: props.background }}>
    <div className="top-section-wrapper">
      <div className="left-section">
        <div className="page-logo">
          <div className="title-decoration"></div>
          <div className="page-title">{props.pageTitle}</div>
        </div>
        <div className="main-heading-wrapper">
          {props.title.map(title => {
            return <div key={title} className="main-heading">{title}</div>
          })}
        </div>
        <div className="sub-heading-wrapper">
          {props.subtitle.map(subtitle => {
            return <div key={subtitle} className="sub-heading">{subtitle}</div>
          })}
        </div>
      </div>
    </div>
  </div>
};

export default TopSection;