import './partner-section.css';

const PartnerSection = (props) => {
  return <div className="partner-section" style={{ background: props.background }}>
    <div className="partner-section-wrapper" style={{ background: props.elementBackground }}>
      <div className="left-section">
        <div className="title">{props.title}</div>
        <div className="description">
          {props.description.map(text => {
            return <div className="text">{text}</div>
          })}
        </div>
      </div>
      <div className="right-section">
        {props.partners.map((partner, index) => {
          if (partner.hide) {
            return null;
          }

          return <div className="partner-wrapper" key={index}>
            {/* {
              index !== 0 ?
                <div class="partner-wrapper-separator"></div>
                :
                null
            } */}
            <div className="partner">
              {
                partner.linkTo ?
                  <a href={partner.linkTo} target="_blank">
                    <div className="partner-image-div">
                      <img
                        {...partner}
                        className={`${partner.className || ''} ${partner.linkTo ? 'cursor-pointer' : ''}`}
                        alt="partner"
                      />
                    </div>
                  </a>
                  :
                  <div className="partner-image-div">
                    <img {...partner} alt="partner" />
                  </div>
              }
            </div>
          </div>
        })}
      </div>
    </div>
  </div>
};

export default PartnerSection;