import ContactUsButton from "../../components/contact-us-button"

const ServiceSection = (props) => {
  return <div className="service-section">
    <div className="service-section-wrapper">
      <div className="title">{props.title}</div>
      <div className="subtitle">
        {props.subtitle.map(text => {
          return <div key={text} className="text">{text}</div>
        })}
      </div>
      <div className="description">
        {props.description.map(text => {
          return <div key={text} className="text">{text}</div>
        })}
      </div>
      <ContactUsButton {...props.detailButton} linkClassName="our-services-contact" />
      <div className="service-catalog">
        {props.serviceList.map(service => {
          return <div key={service.title} className="service-item">
            <img className="service-icon" src={service.icon} alt={service.title} />
            <div className="service-title">{service.title}</div>
            {service.items.map(item => {
              return <div key={item} className="text">{item}</div>
            })}
          </div>
        })}
      </div>

    </div>
  </div>
};

export default ServiceSection;
