let previousPath = '';
export const scrollToTop = (location) => {
  const currentPath = location.pathname;
  if (previousPath.includes('blogs') && currentPath.includes('blogs') && currentPath.split('/').length === 4) {
    window.scrollTo(0, 0);
  }
  else if (previousPath.includes('blogs') && currentPath.includes('blogs')) {
    // do nothing
  } else if (previousPath.includes('careers') && previousPath.includes('overview') && currentPath.includes('overview')) {
    // do nothing
  } else if (previousPath === currentPath) {
    // do nothing
  }
  else {
    window.scrollTo(0, 0);
  }
  previousPath = currentPath;
}
