import TopSection from './top-section';
import OfficeSection from './offices-section';

const ContactUsV2 = (props) => {
  return <div className="contact-us-v2">
    <TopSection {...props.topSection} />
    <OfficeSection {...props.officesSection} />
  </div>
}

export default ContactUsV2;