import './top-section.css';

const TopSection = (props) => {
  return <div className="top-section" style={{ backgroundImage: props.background }}>
    <div className="top-section-overlay" style={{ ...props.topSectionOverlayStyles }}></div>
    <div className="top-section-wrapper">
      <div className="page-logo">
        <div className="title-decoration"></div>
        <div className="page-title">{props.pageTitle}</div>
      </div>
      <div className="main-heading-wrapper">
        {props.title.map(title => {
          return <div key={title} className="main-heading">{title}</div>
        })}
      </div>
    </div>
  </div>
};

export default TopSection;