import './styles.css';

const Input = (props) => {
  const { style, label, ...rest } = props;
  return <div className="custom-input" style={style}>
    <div className="label">{label}</div>
    {props.isRequired ? <sup>*</sup> : null}
    <input {...rest} />
  </div>
};

export default Input;